import React from 'react';

import ReactDOM from 'react-dom';
import './ProjectCard.css';

import CatArcadeIcon from "./games/CatArcade_logo.png";
import Dream15Icon from "./games/Dream15_logo.png";
import PushOutIcon from "./games/PushOut_logo.png";

import cx from 'classnames';

interface ProjectCardProps {
    icon: string;
    title: string;
    num: number;
    googlePlayButton: JSX.Element;
    description: string;
}

function ProjectCard({icon, title, num, googlePlayButton, description}: ProjectCardProps) {
    return (
        <div className="project-card">
            <div className="project-header">
                <h3>#{num} {title}</h3>
                <img title={title} src={icon} className="project-icon" />
            </div>
            <p>{description}</p>
            <div className="project-links">
                {googlePlayButton}
            </div>
        </div>
    )
}

interface ProjectsProps {
    projects?: ProjectCardProps[];
}

const defaultProjects = [
    {
        icon: PushOutIcon,
        title: "Push Out!",
        num: 3,
        googlePlayButton: (<a href='https://play.google.com/store/apps/details?id=dev.joyquest.pushout&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>),
        description: "See who can stay in the ring when you face off against your friends in Push Out!",
    },
    {
        icon: CatArcadeIcon,
        title: "Cat Arcade",
        num: 2,
        googlePlayButton: (<a href='https://play.google.com/store/apps/details?id=dev.joyquest.catarcade&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>),
        description: `Step into the whimsical world of Cat Arcade, where every swipe of a paw brings joy and exciting challenges to your feline friend!`,
    },
    {
        icon: Dream15Icon,
        title: "Dream 15",
        num: 1,
        googlePlayButton: (<a href='https://play.google.com/store/apps/details?id=dev.joyquest.dream15&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>),
        description: `Experience the timeless fun of number slide puzzles with Dream 15, now available on your phone. Dive into our 15 challenges for a satisfying journey, or simply shuffle tiles for endless amusement (they make a perfect fidget toy too)!`,
    },
]

export function Projects({projects = defaultProjects}: ProjectsProps) {
    return (
        <div className="projects-list">
            {projects.map(project => <ProjectCard key={project.num} {...project} />)}
        </div>
    )
}