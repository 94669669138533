import React from 'react';
import MollyBot from './assets/MollyBot.png';
import ReactDOM from 'react-dom';
import './App.css';
import cx from 'classnames';
import SocialBar from "./SocialBar";
import { Projects } from './ProjectCard';

type TabID = "about" | "games"; // TODO: Add prototypes section | "prototypes"; // | ; // TODO: Enable apps section 
const tabIDs: TabID[] = ["about", "games"] // TODO: Add prototypes section, "prototypes"]; //, "apps"]; // TODO: Enable apps section 

function App() {
  const [selectedTab, setSelectedTab] = React.useState<TabID>("about");

  const handleMouseMove = React.useCallback((event: React.MouseEvent) => {
    var left_pupil = document.getElementById("left-pupil")!;
    var right_pupil = document.getElementById("right-pupil")!;

    var pos = normalize(event.pageX, event.pageY);
    // pos.x = pos.x * 26 + 10;
    // pos.y = pos.y * 26 - 10;

    var x = window.innerWidth / 2;
    var y = window.innerHeight / 2;
    
    // pos.x = 25 * pos.x + 5
    // pos.y = 25 * pos.y - 10

    var angle =  Math.atan2(pos.y - 0.45,pos.x - 0.45);
    var result_x = 20 * Math.cos(angle) + 10
    var result_y = 15 * Math.sin(angle) - 10

    left_pupil.style.left = result_x + "px";
    left_pupil.style.top = result_y + "px";

    right_pupil.style.left = result_x + "px";
    right_pupil.style.top = result_y + "px";
  }, []);

  
  const showTab = (tab?: TabID) => {
    const games = document.getElementById("games")!;
    const apps = document.getElementById("apps")!;
    const prototypes = document.getElementById("prototypes")!;
    const about = document.getElementById("about")!;
    for (const tab_element of [games, apps, prototypes, about]) {
      if (tab_element != null) {
        tab_element.style.display = "none";
      }
    }
    if (tab == null) {
      return;
    }
    setSelectedTab(tab)
    switch (tab) {
      case "games":
        games.style.display = "block";
        break;
      /* TODO: Enable apps section
      case "apps":
        apps.style.display = "block";
        break;
      */
     /* TODO: Add prototypes section
      case "prototypes":
        prototypes.style.display = "block";
        break;
      */
      case "about":
        about.style.display = "block";
        break;
      default:
        break;
    }
  }

  const appLinks = React.useMemo(() => {
    return (
      <div className="app-link-container">
        {tabIDs.map(tabID => (
          <a href="#" className={cx("app-link", { "selected": selectedTab == tabID })} onClick={() => showTab(tabID)}>{titleCase(tabID)}</a>
        ))}
      </div>
    )
  }, [selectedTab])


  React.useEffect(() => {
    showTab(selectedTab);
  });

  const validateMailChimp = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
    const hiddenForm: HTMLInputElement = document.getElementsByName("b_b3590d960350ae8db0338e94c_0f80416d72")[0] as HTMLInputElement;
    if (hiddenForm != null && hiddenForm.value != "") {
      event.preventDefault();
    }
  }, []);

  const mailChimp = React.useMemo(() => {
    const actionURL = "https://dev.us10.list-manage.com/subscribe/post?u=b3590d960350ae8db0338e94c&amp;id=0f80416d72&amp;f_id=0066d0e5f0";
    return (
      <div className="mail-chimp-container">
        <h3 className="mail-chimp-title">Please join us on this quest!</h3>
        <form action={actionURL} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" onSubmit={validateMailChimp}>
          <input
            type="email"
            name="EMAIL"
            className="required email"
            id="mce-EMAIL"
            required={true}
            defaultValue=""
            placeholder="Please join our mailing list :)"
          />
          {/** Trick from mail chimp to detect if humans are submitting the form. */}
          <div aria-hidden="true" style={{ position: "absolute", left: "-5000px" }}>
            <input type="text" name="b_b3590d960350ae8db0338e94c_0f80416d72" tabIndex={-1} />
          </div>
          <input type="submit" name="subscribe" id="mc-embedded-subscribe" value="Subscribe" />
        </form>
      </div>
    )
  }, []);

  return (
    <div className="app" onMouseMove={handleMouseMove}>
      <header className="app-header">
        <div className="logo-container">
          <img src={MollyBot} className="app-logo" alt="logo" />
          <div className="left-eye">
            <div id="left-pupil" />
          </div>
          <div className="right-eye">
            <div id="right-pupil" />
          </div>
        </div>
        <div className="app-title-container">
          <h1 className="app-title">Joy Quest</h1>
        </div>
        {appLinks}
        <div id="games" className="tab-content"><Projects /></div>
        {/* <div id="apps" className="tab-content"><p>Apps will be added soon!</p></div> */}
        {/* <div id="prototypes" className="tab-content"><p>Prototypes will be added soon!</p></div> // TODO: Add prototypes section */}
        <div id="about" className="tab-content">
          <p>Welcome to Joy Quest, where our journey is all about crafting joy through the art of game development! We're a small, passionate team led by two brothers who share a dream – to create games and apps that spread happiness far and wide.</p>
          <p>We grew up playing games that made us laugh, think, and feel; now, we're turning those cherished experiences into inspiration for our own creations.</p>
          <p>We believe that games are more than just entertainment; they're a way to connect, to challenge, and to celebrate the joys of life. That's why we pour our hearts every playful moment we design.</p>
        </div>
        {mailChimp}
        <SocialBar />
        <div className="dynamic-spacer" />
        <div className="spacer" />
      </header>

    </div>
  );
}

/** Helpers */
interface Vector {
  x: number, y: number
}

function normalize(x: number, y: number): Vector {
  var length = Math.sqrt(x*x+y*y); //calculating length
  return { "x": x/length, "y": y/length}; //returning normalized vector
}

function titleCase(x: string): string {
  return x.replace(/\w\S*/g, function(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export default App;
