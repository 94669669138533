import React from 'react';

import DiscordIcon from './assets/discord_mark_white.svg';
import InstagramIcon from './assets/instagram_glyph_white.svg';
import XIcon from './assets/x_logo_white.png';
import MediumIcon from "./assets/medium_symbol_white.png";

import ReactDOM from 'react-dom';
import './SocialBar.css';
import cx from 'classnames';

type TabID = "about" | "games" | "apps" | "prototypes";
const tabIDs: TabID[] = ["about", "games", "apps", "prototypes"];

interface SocialBarEntry {
    title: string;
    link: string;
    icon: string;
}

interface SocialBarProps {
    links?: SocialBarEntry[];
}

const defaultLinks = [
    { title: "Discord", link: "https://discord.gg/9PFzf8qeTw", icon: DiscordIcon },
    { title: "Instagram", link: "https://www.instagram.com/joyquestdev/", icon: InstagramIcon },
    { title: "X", link: "https://twitter.com/joyquestdev", icon: XIcon },
    // { title: "Blog", link: "", icon: MediumIcon }
]

function SocialBar({ links = defaultLinks }: SocialBarProps) {
    return (
        <div className="social-bar">
            {links.map(entry => {
                return <SocialButton key={entry.title} {...entry} />;
            })}
        </div>
    );
}

function SocialButton({title, link, icon}: SocialBarEntry) {
    return (
        <a
            href={link}
            className="social-button"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img title={title} src={icon} className="social-button-icon" />
        </a>
    );
}

export default SocialBar